'use client';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import type { ReactNode } from 'react';

const variants = {
	hidden: { opacity: 0.01 },
	enter: { opacity: 1 },
};

const GAInitializer = dynamic(() => import('@/components/GAInitializer'), {
	ssr: false,
});

const GlobalWrapper = ({ children }: { children: ReactNode }) => {
	return (
		<>
			<GAInitializer />
			<motion.main
				variants={variants}
				initial='hidden'
				animate='enter'
				transition={{ type: 'linear' }}
			>
				{children}
			</motion.main>
		</>
	);
};

export default GlobalWrapper;
